























































































































































































































































































































































import ViewModel from '@/views/service/CrewAdvantage.ts';
export default ViewModel;
